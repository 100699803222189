import styled from 'styled-components';

import { fonts } from '@/theme';

const { text, smallText } = fonts;

export default styled.p`
  ${({ small }) => (small ? smallText : text)}
  margin: ${({ margin }) => margin || '1em 0'};
  min-height: 1em;
`;
