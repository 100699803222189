import React from 'react';
import PropTypes from 'prop-types';

import { Message } from '../styles';

import { TextInput, TextArea } from './styles';

const Input = ({ value, name, placeholder, onChange, error, isMessage }) => {
  const InputElement = isMessage ? TextArea : TextInput;

  return (
    <>
      <InputElement aria-label={name} name={name} placeholder={placeholder} value={value} onChange={onChange} />
      {error && <Message error>{error}</Message>}
    </>
  );
};

Input.defaultProps = {
  error: '',
  isMessage: false,
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  isMessage: PropTypes.bool,
};

export default Input;
