import styled from 'styled-components';

import { fonts, colors, metrics } from '@/theme';

const { black, gray } = colors;

export const TextInput = styled.input`
  ${fonts.text}
  border-bottom: solid 1px ${black};
  width: 100%;
  margin-top: 15px;
  &::placeholder {
    color: ${gray};
  }
`;

export const TextArea = styled.textarea`
  ${fonts.text}
  border-bottom: solid 1px ${black};
  width: 100%;
  margin-top: 30px;
  min-height: 130px;
  resize: none;
  &::placeholder {
    color: ${gray};
  }
  @media (max-width: ${metrics.mobile}px) {
    min-height: 65px;
  }
`;
