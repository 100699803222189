import styled from 'styled-components';

import { fonts, colors } from '@/theme';

const { black } = colors;

export default styled.button`
  ${fonts.button}
  height: 43px;
  border: 1px solid ${black};
  border-radius: 22px;
  background: transparent;
  padding: 0 20px 2px 20px;
  transition: all 0.3s;
  &:hover {
    background: ${black};
  }
  &:disabled {
    background: transparent;
  }
`;
