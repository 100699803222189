import React, { useCallback } from 'react';

import { postContactRequest } from '@/server-api';
import { useAsyncState } from '@/utils/hooks';

import TextInput from './TextInput';
import validationSchema from './validationSchema';
import { extractValidateErrors, hasValidErrors, prepareData } from './utils';
import { INITIAL_VALUES, FIELDS } from './const';
import { Form, Button, Message } from './styles';

const ContactForm = () => {
  const [state, setState] = useAsyncState(INITIAL_VALUES);
  const [alerts, setAlerts] = useAsyncState({});

  const postData = useCallback(
    (data) => {
      postContactRequest(prepareData(data))
        .then(() => {
          setAlerts({ success: true });
          setState(INITIAL_VALUES);
        })
        .catch(() => {
          setAlerts({ error: true });
        });
    },
    [setAlerts, setState]
  );

  const handleValidationErrors = useCallback((errors) => setAlerts({ errors: extractValidateErrors(errors) }), [setAlerts]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setState({ ...state, [name]: value });
      setAlerts({
        error: null,
        errors: { ...alerts.errors, [name]: '' },
      });
    },
    [setState, state, setAlerts, alerts]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setAlerts({ loading: true });

      validationSchema.validate(state, { abortEarly: false }).then(postData).catch(handleValidationErrors);
    },
    [handleValidationErrors, postData, setAlerts, state]
  );

  return (
    <Form onSubmit={handleSubmit}>
      {FIELDS.map(({ id, placeholder, isMessage }) => (
        <TextInput
          key={id}
          placeholder={placeholder}
          name={id}
          value={state[id]}
          onChange={handleChange}
          error={alerts.errors?.[id]}
          isMessage={Boolean(isMessage)}
        />
      ))}
      <Button type="submit" disabled={hasValidErrors(alerts.errors) || alerts.loading}>
        Nachricht senden
      </Button>
      {alerts.error && (
        <Message error>
          Ihre Anfrage konnte nicht übermittelt werden. Bitte versuchen Sie es später noch einmal oder schreiben Sie uns via
          info@meditrova.de.
        </Message>
      )}
      {alerts.success && !alerts.error && (
        <Message>Vielen Dank für Ihre Nachricht. Wir melden uns so schnell wie möglich. </Message>
      )}
    </Form>
  );
};

export default ContactForm;
