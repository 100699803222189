import styled from 'styled-components';

import Btn from '@/components/Button';
import { metrics, fonts, colors } from '@/theme';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media (max-width: ${metrics.mobile}px) {
    margin-bottom: 60px;
  }
`;

export const Button = styled(Btn)`
  width: 100%;
  margin: 20px 0 15px 0;
`;

export const Message = styled.p`
  ${fonts.smallText}
  font-size: 15px;
  color: ${({ error }) => (error ? colors.red : colors.green)};
  margin: 0;
`;
