import { NAME, COMPANY, EMAIL, MESSAGE, CONTACT } from './const';

export const extractValidateErrors = ({ inner = [] }) =>
  inner.reduce((acc, { path, message }) => {
    acc[path] = message;
    return acc;
  }, {});

export const hasValidErrors = (errors) => Boolean(Object.values(errors || {}).filter(Boolean).length);

export const prepareData = (data = {}) => ({
  [CONTACT]: {
    [NAME]: data[NAME] || '',
    [COMPANY]: data[COMPANY] || '',
    [EMAIL]: data[EMAIL] || '',
  },
  [MESSAGE]: data[MESSAGE] || '',
});
