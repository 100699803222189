import { useStaticQuery, graphql } from 'gatsby';

export const useData = () => {
  const { contentsJson } = useStaticQuery(
    graphql`
      query homeQuery {
        contentsJson(home: { id: { eq: "home" } }) {
          home {
            contact {
              description
              title
            }
            main {
              description
              title
            }
          }
        }
      }
    `
  );

  return contentsJson.home;
};
