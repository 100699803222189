import { useState, useRef, useCallback, useEffect } from 'react';

export const useAsyncState = (init) => {
  const isMount = useRef(true);
  const [state, setState] = useState(init);

  const handleChange = useCallback((data) => {
    if (isMount.current) {
      setState(data);
    }
  }, []);

  useEffect(
    () => () => {
      isMount.current = false;
    },
    []
  );

  return [state, handleChange, isMount];
};
