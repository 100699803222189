import styled from 'styled-components';

import { metrics, colors } from '@/theme';
import Text from '@/components/Text';
import Subtitle from '@/components/Subtitle';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  @media (max-width: ${metrics.mobile}px) {
    flex-direction: column;
  }
`;

export const Description = styled(Text)`
  margin-bottom: 60px;
  @media (max-width: ${metrics.mobile}px) {
    margin-bottom: 100px;
  }
`;

export const ContactText = styled(Text)`
  margin-bottom: 60px;
  @media (max-width: ${metrics.mobile}px) {
    margin-bottom: 80px;
  }
`;

export const PaintedSection = styled.div`
  width: 50%;
  padding: 0 20px;
  @media (max-width: ${metrics.mobile}px) {
    width: 100%;
    background: ${colors.yellow};
  }
`;

export const PaintedBox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: ${colors.yellow};
  z-index: -1;
  @media (max-width: ${metrics.mobile}px) {
    display: none;
  }
`;

export const SectionTitle = styled(Subtitle)`
  display: block;
  position: fixed;
  top: 0;
  left: 50%;
  right: 0;
  padding: 2px 20px;
  background: ${colors.yellow};
  z-index: 10;
  @media (max-width: ${metrics.mobile}px) {
    position: static;
    padding: 10px 0;
  }
`;
