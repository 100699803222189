import React from 'react';

import Title from '@/components/Title';
import Section from '@/components/Section';

import ContactForm from './ContactForm';
import { useData } from './hooks';
import { Container, Description, ContactText, PaintedSection, SectionTitle, PaintedBox } from './styles';

const Home = () => {
  const { contact, main } = useData();

  return (
    <Container>
      <Section>
        <Title>{main.title}</Title>
        <Description>{main.description}</Description>
      </Section>
      <PaintedSection>
        <SectionTitle>kontakt</SectionTitle>
        <PaintedBox />
        <Title>{contact.title}</Title>
        <ContactText>{contact.description}</ContactText>
        <ContactForm />
      </PaintedSection>
    </Container>
  );
};

export default Home;
