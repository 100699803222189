export const NAME = 'name';
export const COMPANY = 'organization';
export const EMAIL = 'email';
export const MESSAGE = 'message';

export const CONTACT = 'contact';

export const INITIAL_VALUES = {
  [NAME]: '',
  [COMPANY]: '',
  [EMAIL]: '',
  [MESSAGE]: '',
};

export const FIELDS = [
  {
    id: NAME,
    placeholder: 'Name',
  },
  {
    id: COMPANY,
    placeholder: 'Organisation',
  },
  {
    id: EMAIL,
    placeholder: 'E-Mail',
  },
  {
    id: MESSAGE,
    placeholder: 'Nachricht',
    isMessage: true,
  },
];
